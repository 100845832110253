import isValidAddress from '@components/fields/Place/helpers/isValidAddress'

export default function validate({address, addressSecondary}) {
  const errors = {}

  if (!isValidAddress(address)) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'address' does not exist on type '{}'.
    errors.address = 'La dirección debe contener un nombre y un número'
  }

  if (!address.length) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'address' does not exist on type '{}'.
    errors.address = 'La dirección es obligatoria'
  }

  if (!addressSecondary.length) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'addressSecondary' does not exist on type... Remove this comment to see the full error message
    errors.addressSecondary = 'La comuna es obligatoria'
  }

  return {errors}
}
