import gql from 'graphql-tag'

export default async function getPlace({placeId, client}) {
  const {data} = await client.query({
    query: gql`
      query getPlace($placeId: ID) {
        place(placeId: $placeId) {
          _id
          text
          secondaryText
        }
      }
    `,
    variables: {placeId},
    fetchPolicy: 'network-only'
  })

  if (!data || !data.place) return null

  return data.place
}
