import React from 'react'
import {GoogleMap, Polygon} from 'react-google-maps'
import MapsProvider from '@components/MapsProvider'
import useRef from '@hooks/useRef'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

const options = {
  zoomControl: true,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false
}

export default function Map(props) {
  const mapRef = useRef()

  const {center, defaultCenter, onChangeCenter, onDragStart, onLoaded, stores, showAreas} = props

  const onDragEnd = () => {
    const newCenter = {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      lat: mapRef.current.getCenter().lat(),
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      lng: mapRef.current.getCenter().lng()
    }
    return onChangeCenter({center: newCenter})
  }

  return (
    <>
      {(center || defaultCenter) && (
        <MapsProvider
          containerElementClassName={styles.map}
          loadingElementClass={styles.loading}
          onLoaded={onLoaded}>
          <GoogleMap
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            fullscreenControl={false}
            clickableIcons={false}
            defaultZoom={14}
            center={center || defaultCenter}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            ref={mapRef}
            options={options}>
            {showAreas &&
              stores.map(store => (
                <>
                  {store.zones.map(zone => (
                    <Polygon
                      paths={zone.deliveryLimits}
                      key={zone._id}
                      options={{
                        strokeWeight: 1,
                        fillOpacity: 0.2,
                        fillColor: 'blue',
                        strokeColor: 'blue'
                      }}
                    />
                  ))}
                </>
              ))}
          </GoogleMap>
        </MapsProvider>
      )}
    </>
  )
}

Map.propTypes = {
  onChangeCenter: PropTypes.func,
  onLoaded: PropTypes.func,
  onDragStart: PropTypes.func,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      acceptDelivery: PropTypes.bool,
      address: PropTypes.object
    })
  ),
  showAreas: PropTypes.bool
}
