import React from 'react'
import {GoLocation} from 'react-icons/go'

import styles from './styles.module.css'

export default function EnterAddressManually({onClick}) {
  return (
    <>
      <div className={styles.separator} />
      <div className={styles.button} onClick={onClick}>
        <GoLocation size={15} />
        <div className={styles.subtitle}>Ingresa tu dirección manualmente</div>
      </div>
    </>
  )
}
