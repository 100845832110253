import React from 'react'
import {components} from 'react-select'
import useState from '@hooks/useState'

import EnterAddressManually from './EnterAddressManually'
import Loading from './Loading'
import Places from './Places'

import styles from './styles.module.css'

export default function Search(props) {
  const {loading, onChange, showCustom, noDefault, value, passProps} = props
  const [showFixLocation, setShowFixLocation] = useState()

  const onInputChange = value => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    setShowFixLocation(value && value.length > 3)
  }

  if (loading) {
    return <Loading />
  }

  function MenuList(props) {
    return (
      <components.MenuList {...props}>
        {props.children}
        {showFixLocation && <EnterAddressManually onClick={() => showCustom(true)} />}
      </components.MenuList>
    )
  }

  return (
    <Places
      onInputChange={onInputChange}
      onChange={onChange}
      noDefault={noDefault}
      loadingMessage={() => 'Cargando direcciones'}
      value={value}
      unrestrictedSearch={false}
      {...props}
      // default is 300. Override so the option to add a manual address is displayed without scrolling.
      maxMenuHeight={350}
      components={{
        // add EnterAddressManually to every search with results.
        MenuList,
        // remove default dropdown arrow.
        DropdownIndicator: null,
        IndicatorSeparator: null
      }}
      {...passProps}
      noOptionsMessage={() => (
        <div>
          <div className={styles.title}>
            {showFixLocation
              ? 'No hemos podido encontrar tu dirección'
              : 'Escribe tu dirección con calle y número para que aparezcan los resultados'}
          </div>
        </div>
      )}
    />
  )
}
