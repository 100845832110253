import React from 'react'
import Place from '@components/fields/Place/Search/Place'

export default function getPlacetoRender(place) {
  return {
    place,
    label: <Place place={place} />,
    value: place._id
  }
}
