import React from 'react'
import AsyncSelect from 'react-select/async'
import loadOptions from '@components/fields/Place/helpers/loadOptions'
import useWebsiteId from '@hooks/useWebsiteId'
import {useClient} from 'apollo-hooks'

export default function Places(props) {
  const {
    unrestrictedSearch,
    userId,
    noDefault,
    placeholderText,
    sessionTokenGoogleMaps,
    selectedCity
  } = props
  const passProps = props.passProps || {}
  const {websiteId: fallbackWebsiteId, countryCodes} = passProps
  const websiteId = useWebsiteId() || fallbackWebsiteId
  const client = useClient()

  return (
    <AsyncSelect
      key={userId || 'loaded'}
      classNamePrefix="orion-select"
      placeholder={placeholderText || 'Escribe tu dirección'}
      defaultOptions={!noDefault}
      loadOptions={loadOptions({
        userId,
        websiteId,
        unrestrictedSearch,
        selectedCity,
        client,
        sessionTokenGoogleMaps,
        ...(countryCodes && {countryCodes})
      })}
      isClearable
      {...props}
    />
  )
}
