import gql from 'graphql-tag'

export default async function getGeocodePlace({location, client}) {
  const {data} = await client.query({
    query: gql`
      query getGeocodePlace($location: JSON) {
        place: searchGeocodePlace(location: $location) {
          _id
          text
          secondaryText
          location
        }
      }
    `,
    variables: {location},
    fetchPolicy: 'network-only'
  })

  if (!data || !data.place) return null

  return data.place
}
