import getPlaces from './getPlaces'
import placeToRender from './placeToRender'

export default async function getFormattedPlaces({
  inputValue,
  userId,
  websiteId,
  unrestrictedSearch,
  client,
  countryCodes,
  sessionTokenGoogleMaps
}) {
  const places = await getPlaces({
    inputValue,
    userId,
    websiteId,
    unrestrictedSearch,
    client,
    countryCodes,
    sessionTokenGoogleMaps
  })

  if (!places) return null

  return places.map(place => placeToRender(place))
}
