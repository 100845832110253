import React, {useEffect} from 'react'
import ClickOutside, {Props as ClickOutsideProps} from '@components/ClickOutside'
import useKeyboardEvent from '@hooks/useKeyboardEvent'
import classnames from 'classnames'

import styles from './styles.module.css'

global.isModalOpen = false

interface BaseProps {
  close?: (e: any) => void
  customClass?: string
  contentClassName?: string
  top?: any
  bottom?: any
  topStyle?: string
  bottomStyle?: string
}

export type Props = BaseProps & Omit<ClickOutsideProps, 'onClickOutside'>

export default function ModalInner(props: Props) {
  const {contentClassName, avoidElementsClass, avoidElementName} = props
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    global.isModalOpen = true
    return () => {
      document.body.style.overflow = 'unset'
      global.isModalOpen = false
    }
  }, [])
  const close = e => {
    if (props.close) {
      props.close(e)
    }
  }

  useKeyboardEvent('Escape', close)

  const topStyle = props.topStyle ? classnames(styles.top, props.topStyle) : styles.top
  const bottomStyle = props.bottomStyle
    ? classnames(styles.bottom, props.bottomStyle)
    : styles.bottom

  return (
    <div className={styles.container}>
      <ClickOutside
        avoidElementName={avoidElementName}
        avoidElementsClass={avoidElementsClass}
        onClickOutside={close}
        className={classnames(styles.modalInner, props.customClass)}>
        {props.top ? <div className={topStyle}>{props.top}</div> : null}
        <div className={classnames(styles.content, contentClassName)}>{props.children}</div>
        {props.bottom ? <div className={bottomStyle}>{props.bottom}</div> : null}
      </ClickOutside>
    </div>
  )
}
