import React from 'react'
import {withGoogleMap, withScriptjs} from 'react-google-maps'

import styles from './styles.module.css'

type InnerMapsProviderProps = {
  children?: React.ReactNode
  onLoaded?: () => void
  googleMapURL?: string
  loadingElement?: React.ReactNode
  containerElement?: React.ReactNode
  mapElement?: React.ReactNode
}
// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withScriptjs
// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withGoogleMap
class InnerMapsProvider extends React.Component<InnerMapsProviderProps> {
  props: InnerMapsProviderProps

  componentDidMount() {
    this.props?.onLoaded()
  }

  render() {
    return this.props.children
  }
}

type MapsProviderProps = {
  children?: React.ReactNode
  onLoaded?: () => void
  style?: object
  containerElementClassName?: string
  loadingElementClass?: string
}

export default class MapsProvider extends React.Component<MapsProviderProps> {
  props: MapsProviderProps

  static defaultProps = {
    onLoaded: () => {}
  }

  render() {
    const googleKey = 'AIzaSyDGgJhr2Pt2TqMhGfrUv_W6Ia08ACo7Kdk'
    return (
      <InnerMapsProvider
        onLoaded={this.props.onLoaded}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=visualization,geometry,drawing,places&key=${googleKey}`}
        loadingElement={<div className={this.props.loadingElementClass || styles.loadingElement} />}
        containerElement={
          <div
            className={this.props.containerElementClassName || styles.containerElement}
            style={this.props.style}
          />
        }
        mapElement={<div className={styles.mapElement} />}>
        {this.props.children}
      </InnerMapsProvider>
    )
  }
}
