import React, {useEffect, useState} from 'react'
import {MdClose as CloseIcon, MdLocationOn as LocationIcon} from 'react-icons/md'
import Button from '@components/Button'
import Places from '@components/fields/Place/Search/Places'
import useWebsiteId from '@hooks/useWebsiteId'
import Checkbox from '@packages/justo-parts/lib/components/fields/Checkbox'

import getPlaceToRender from '../helpers/placeToRender'

import Form from './Form'
import Map from './Map'
import RequestLocation from './RequestLocation'
import useCustomPlace from './useCustomPlace'

import styles from './styles.module.css'

const WEBSITES_WITH_HIDDEN_AREAS = ['cRyFRFomeept9LCwn']

export default function CustomPlace(props) {
  const {close, onSelect} = props

  const [className, setClassName] = useState()
  const [mapLoaded, setMapLoaded] = useState()
  const websiteId = useWebsiteId()
  // Caso especial para Dunking Colombia que tiene demasiadas áreas de cobertura
  const [showAreas, setShowAreas] = useState(
    WEBSITES_WITH_HIDDEN_AREAS.includes(websiteId) ? false : true
  )
  const [showConfirm, setShowConfirm] = useState(false)

  const {
    place,
    defaultPlace,
    error,
    loading,
    errorMessage,
    stores,
    onSelectPlace,
    onGetUserLocation,
    onChangeCenter
  } = useCustomPlace({
    close,
    stylesDown: styles.down,
    setClassName
  })

  useEffect(() => {}, [showAreas])

  // This confirmation form is required because sometimes the pin on the map
  // will not load an address and a secondary address (like on small urban roads)
  if (showConfirm) {
    return (
      <div className={styles.container}>
        <h2 className={styles.title}>Guardar dirección</h2>
        <Form place={place} onConfirm={onSelect} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div />
        <h3>Ingresa tu dirección</h3>
        <div className={styles.close} onClick={close}>
          <CloseIcon size={30} />
        </div>
      </div>
      <div className={styles.subtext}>
        <span className={styles.secondaryText}>
          La <strong>sección azul</strong> representa la <strong>zona de despacho</strong>. Puedes
          moverte en el mapa para ajustar tu dirección de entrega.
        </span>
      </div>
      <Places
        loadingMessage={() => 'Cargando direcciones'}
        noOptionsMessage={() => 'No se han encontrado direcciones'}
        placeholder="Ingresa una referencia para encontrar tu dirección"
        onChange={onSelectPlace}
        noDefault={true}
        unrestrictedSearch
        autoFocus
        value={place ? getPlaceToRender(place) : null}
      />
      <div className="os-input-error">{error}</div>
      {(place || defaultPlace) && (
        <>
          <div className={styles.mapContainer}>
            <Map
              onChangeCenter={onChangeCenter}
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              onDragStart={() => setClassName(styles.up)}
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
              onLoaded={() => setMapLoaded(true)}
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              center={place && place.location}
              defaultCenter={defaultPlace && defaultPlace.location}
              stores={stores}
              showAreas={showAreas}
            />
            {mapLoaded ? (
              <LocationIcon size={35} className={styles.marker + ' ' + className} />
            ) : null}
          </div>
          <div className={styles.mapOptions}>
            <Checkbox
              label={'Mostrar/Ocultar coberturas de áreas de despacho'}
              value={showAreas}
              onChange={() => setShowAreas(show => !show)}
            />
          </div>
        </>
      )}
      <div className={styles.requestLocation}>
        <RequestLocation onGetUserLocation={onGetUserLocation} />
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.button}
          loading={loading}
          disabled={errorMessage || !place}
          onClick={() => setShowConfirm(true)}
          primary
          fullWidth>
          Continuar
        </Button>
      </div>
    </div>
  )
}
