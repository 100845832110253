import gql from 'graphql-tag'

export default async function getPlaces({
  inputValue,
  userId,
  websiteId,
  unrestrictedSearch,
  client,
  countryCodes,
  sessionTokenGoogleMaps
}) {
  const {data} = await client.query({
    query: gql`
      query searchPlaces(
        $filter: String
        $userId: String
        $websiteId: ID
        $unrestrictedSearch: Boolean
        $countryCodes: [String]
        $sessionTokenGoogleMaps: String
      ) {
        places: searchPlace(
          filter: $filter
          userId: $userId
          websiteId: $websiteId
          unrestrictedSearch: $unrestrictedSearch
          countryCodes: $countryCodes
          sessionTokenGoogleMaps: $sessionTokenGoogleMaps
        ) {
          text
          secondaryText
          _id
        }
      }
    `,
    variables: {
      filter: inputValue,
      userId,
      websiteId,
      unrestrictedSearch,
      countryCodes,
      sessionTokenGoogleMaps
    },
    fetchPolicy: 'network-only'
  })

  if (!data || !data.places) return null

  return data.places
}
