import gql from 'graphql-tag'

import getPlace from './getPlace'

export default async function getDefaultPlace({placeId, client}) {
  const cacheResult = client.readFragment({
    id: 'Place:' + placeId,
    fragment: gql`
      fragment placeInformationForAutocomplete on Place {
        _id
        text
        secondaryText
      }
    `
  })

  if (cacheResult) return cacheResult

  return await getPlace({placeId, client})
}
