import {useState} from 'react'
import getGeocodePlace from '@components/fields/Place/helpers/getGeocodePlace'
import getPlaceWithCoords from '@components/fields/Place/helpers/getPlaceWithCoords'
import useDisableScroll from '@hooks/useDisableScroll'
import useKeyboardEvent from '@hooks/useKeyboardEvent'
import {useClient} from 'apollo-hooks'

import useStores from './useStores'

export default function useCustom({close, stylesDown, setClassName}) {
  const [place, setPlace] = useState()
  const [error, setError] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState()

  const client = useClient()
  const stores = useStores()

  useKeyboardEvent('Escape', () => {
    close()
  })

  useDisableScroll(true)

  const defaultPlace = stores.length > 0 && stores[0].address && stores[0].address.place

  const onSelectPlace = async value => {
    if (!value) {
      setPlace(null)
      return
    }

    const {
      place: {_id: placeId}
    } = value

    const place = await getPlaceWithCoords({placeId, client})
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    if (!place) return setError('Por favor ingresa una dirección más precisa')

    setPlace(place)
  }

  const onGetUserLocation = async ({location}) => {
    const place = await getGeocodePlace({location, client})
    setPlace(place)
  }

  const onChangeCenter = ({center}) => {
    setClassName(stylesDown)
    getPlaceFromCoords({location: center})
  }

  const getPlaceFromCoords = async ({location}) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    setErrorMessage()
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    setLoading(true)

    const place = await getGeocodePlace({location, client})
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    setLoading(false)

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    if (!place) return setErrorMessage('No se ha encontrado información de la dirección')
    setPlace(place)
  }

  return {
    place,
    error,
    loading,
    errorMessage,
    stores,
    defaultPlace,
    onSelectPlace,
    onGetUserLocation,
    onChangeCenter
  }
}
