import getDefaultPlace from './getDefaultPlace'
import placeToRender from './placeToRender'

export default async function getFormattedDefaultPlace({placeId, client}) {
  const place = await getDefaultPlace({placeId, client})

  if (!place) return null

  return placeToRender(place)
}
