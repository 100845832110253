import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

const GET_STORES_QUERY = gql`
  query getStoresZonesPlaceField_cached($websiteId: ID) {
    stores(websiteId: $websiteId) {
      items {
        _id
        acceptDelivery
        zones {
          _id
          deliveryLimits
        }
        address {
          place {
            _id
            text
            secondaryText
            location
          }
        }
      }
    }
  }
`

export default function useStores() {
  const websiteId = useWebsiteId()

  const {data} = useQuery({query: GET_STORES_QUERY, variables: {websiteId}, omit: !websiteId})

  const stores = data && data.stores

  if (!stores) {
    return []
  }

  return stores.items.filter(store => store.acceptDelivery && store.address && store.address.place)
}
