import React from 'react'
import Button from '@components/Button'
import useMessage from '@hooks/useMessage'
import useState from '@hooks/useState'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'

import validate from './validate'

import styles from './styles.module.css'

export default function Form(props) {
  const {place, onConfirm} = props
  const {text, secondaryText, location, _id: placeId} = place
  const mutate = useMutate()
  const showMessage = useMessage()
  const [errors, setErrors] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)
  const [address, setAddress] = useState(text)
  const [addressSecondary, setAddressSecondary] = useState(secondaryText)

  const save = async () => {
    try {
      setLoading(true)
      const {place} = await mutate({
        mutation: gql`
          mutation createCustomPlace(
            $placeId: ID
            $text: String
            $secondaryText: String
            $address: String
            $addressSecondary: String
            $location: JSON
          ) {
            place: createCustomPlace(
              placeId: $placeId
              text: $text
              secondaryText: $secondaryText
              address: $address
              addressSecondary: $addressSecondary
              location: $location
            ) {
              _id
              text
              secondaryText
              location
            }
          }
        `,
        variables: {
          placeId,
          text: text ? text : 'undefined',
          secondaryText: secondaryText ? secondaryText : 'undefined',
          address,
          addressSecondary,
          location
        }
      })

      setLoading(false)
      if (!place) return
      return onConfirm && onConfirm({place})
    } catch (error) {
      showMessage(error)
      setLoading(false)
    }
  }

  const submit = async () => {
    const {errors} = validate({address, addressSecondary})
    setErrors(errors)

    if (Object.keys(errors).length > 0) return

    await save()
  }

  return (
    <div className={styles.container}>
      <div>
        <div className="label">Dirección</div>
        <input
          className="os-input-text"
          value={address}
          onChange={event => setAddress(event.target.value)}
        />
        {errors && errors.address ? <div className="os-input-error">{errors.address}</div> : null}
      </div>
      <div className={styles.field}>
        <div className="label">Comuna/Estado</div>
        <input
          className="os-input-text"
          value={addressSecondary}
          onChange={event => setAddressSecondary(event.target.value)}
        />
        {errors && errors.addressSecondary && (
          <div className="os-input-error">{errors.addressSecondary}</div>
        )}
      </div>
      <Button loading={loading} onClick={submit} primary fullWidth>
        Continuar
      </Button>
    </div>
  )
}
