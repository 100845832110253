import React from 'react'

import styles from './styles.module.css'

export default function Place({place}) {
  return (
    <div className={styles.place}>
      <div className={styles.mainText}>{place.text}</div>
      <div className={styles.secondaryText}>{place.secondaryText}</div>
    </div>
  )
}
