import React from 'react'
import AsyncSelect from 'react-select/async'

export default function Loading(props) {
  return (
    <AsyncSelect
      key="loadingcomp"
      placeholder="Cargando..."
      classNamePrefix="orion-select"
      isLoading
      isDisabled
      {...props}
    />
  )
}
