import debounce from 'lodash/debounce'

import getFormattedPlaces from './getFormattedPlaces'

export default function loadOptions({
  userId,
  websiteId,
  unrestrictedSearch,
  selectedCity,
  client,
  countryCodes,
  sessionTokenGoogleMaps
}) {
  const searchFn = debounce(async (inputValue, callback) => {
    try {
      const result = await getFormattedPlaces({
        inputValue,
        userId,
        websiteId,
        unrestrictedSearch,
        client,
        countryCodes,
        sessionTokenGoogleMaps
      })
      callback(null, result)
    } catch (error) {
      callback(error, null)
    }
  }, 300)

  return term => {
    if (selectedCity) {
      term = `${term} ${selectedCity.value}`
    }

    return new Promise((resolve, reject) => {
      searchFn(term, (error, response) => {
        if (error) reject(error)
        else resolve(response)
      })
    })
  }
}
