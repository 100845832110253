import React from 'react'
import {MdLocationOn as LocationIcon} from 'react-icons/md'
import Button from '@components/Button'
import useEffect from '@hooks/useEffect'
import useState from '@hooks/useState'

import styles from './styles.module.css'

export default function RequestLocation(props) {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<any>()
  const [notGranted, setNotGranted] = useState(false)

  const {onGetUserLocation} = props

  useEffect(() => {
    if (!state) return
    if (state == 'prompt' || state == 'granted') {
      return getCurrentPosition()
    }

    setNotGranted(true)
    setLoading(false)
  }, [state])

  const requestLocationPermission = async () => {
    setLoading(true)

    const result = await navigator.permissions.query({name: 'geolocation'})

    if (state && state === result.state) {
      getCurrentPosition()
    } else {
      setState(result.state)
    }

    result.onchange = function () {
      setState(result.state)
    }
  }

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(async loc => {
      setLoading(true)
      const {
        coords: {latitude: lat, longitude: lng}
      } = loc
      await onGetUserLocation({location: {lat, lng}})
      setLoading(false)
    })
  }

  return (
    <div>
      {notGranted ? (
        <div className={styles.containerError}>
          <div className={styles.containerMessage}>
            <div className={styles.circle}>
              <LocationIcon size={15} className={styles.marker} />
            </div>
            <h4>No se concedió el permiso de tu ubicación</h4>
          </div>
          <span className={styles.secondaryText}>
            Por favor habilita los permisos de tu navegador.
          </span>
        </div>
      ) : (
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            onClick={requestLocationPermission}
            loading={loading}
            fullWidth>
            <LocationIcon size={20} />
            Usar mi ubicación
          </Button>
        </div>
      )}
    </div>
  )
}
