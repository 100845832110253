import React from 'react'
import Modal from '@components/Modal'
import useEffect from '@hooks/useEffect'
import useState from '@hooks/useState'
import {useClient} from 'apollo-hooks'
import omit from 'lodash/omit'
import {v4 as uuidv4} from 'uuid'

import getFormattedDefaultPlace from './helpers/getFormattedDefaultPlace'
import placeToRender from './helpers/placeToRender'
import Custom from './Custom'
import Search from './Search'

export default function Place(props) {
  const {passProps = {}} = props
  const initialValue = props.value
  const client = useClient()
  const [loading, setLoading] = useState()
  const [value, setValue] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sessionTokenGoogleMaps, setSessionTokenGoogleMaps] = useState(null)
  const openCustom = passProps.openCustom || isModalOpen
  const setOpenCustom = passProps.setOpenCustom || setIsModalOpen

  useEffect(() => {
    async function getDefault() {
      const place = await getFormattedDefaultPlace({placeId: initialValue, client})
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ place: any; label: JSX.Element... Remove this comment to see the full error message
      setValue(place)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      setLoading(false)
    }

    function createSessionToken() {
      setSessionTokenGoogleMaps(uuidv4())
    }

    if (initialValue) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      setLoading(true)
      getDefault()
      createSessionToken()
    }
  }, [])

  const onChange = place => {
    setValue(place)
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    setErrorMessage()

    // if (place && place.place && !isValidAddress(place.place.text)) {
    //   setErrorMessage('Debes seleccionar la dirección con nombre de la calle y número')
    //   return props.onChange(null)
    // }

    if (place) return props.onChange(place.value)
    return props.onChange(null)
  }

  const onSelectCustomLocation = ({place}) => {
    setOpenCustom(false)
    onChange(placeToRender(place))
  }

  /* to not call onChange that comes from the Form */
  const passingProps = omit(props, 'onChange', 'value')
  passingProps.sessionTokenGoogleMaps = sessionTokenGoogleMaps
  return (
    <div>
      <div className="label">{props.label}</div>
      <Search
        showCustom={() => setOpenCustom(true)}
        loading={loading}
        onChange={onChange}
        value={value}
        {...passingProps}
      />
      <div className="description">{props.description}</div>
      <div className="os-input-error">{errorMessage || props.errorMessage}</div>
      {openCustom ? (
        <Modal close={() => setOpenCustom(false)}>
          <Custom
            close={() => setOpenCustom(false)}
            onSelect={onSelectCustomLocation}
            {...passingProps}
          />
        </Modal>
      ) : null}
    </div>
  )
}
